import React from "react"
import { Link } from 'gatsby'
import WorkUnitFeatured from './WorkUnitFeatured'

import BarEnd from '../images/svg/barend.inline.svg'
import DkGreenCube from '../images/svg/DK_GREEN_cube.inline.svg'
import OrangeCube from '../images/svg/ORANGE_cube.inline.svg'

const ContentHome = (props) => {
    
    return (
    <>
        <section className="section">
            <div className="content" id="main-content">
                <div className="hero all-center">
                    <h1><span>LET'S CREATE</span><span>SOMETHING</span><span>FROM</span><span>NOTHING</span></h1>
                </div>
            </div>
        </section>
        <section className="section" aria-label="featured work section">
            <div className="content">
                <p className="sr-only" tabIndex="0">Two of the most recent projects are featured here.</p>
                <div className="top-title">
                    <DkGreenCube aria-hidden="true" />
                    <h2 className="h1"><span>Featured Work</span></h2>
                    <div  className="more-link">
                        <Link to="/development"><span className="sr-only">View</span> All <span>web development work</span> <BarEnd aria-hidden="true" /></Link>
                        
                    </div>
                </div>
                <div className="featured-work">
                    <div className="featured-backdrop">

                        <WorkUnitFeatured />

                     </div> {/*  .featured-backdrop */}


                     <div className="bot-title">
                    <OrangeCube aria-hidden="true" />
                    <div  className="more-link">
                        <Link to="/development"><span className="sr-only">View</span> All <span>web development work</span> <BarEnd aria-hidden="true" /></Link>  
                    </div>
                </div>
                </div> {/* end featured-work */}
            </div>
        </section>

    </>
)};




export default ContentHome;
