import React from 'react'
import { useSelector } from 'react-redux'

import Helmet from '../Helmet'
import Header from '../Header'
import Footer from '../Footer'
import ClientOnly from '../ClientOnly'
import ContentHome from '../ContentHome'
import LogoTypeAnimation from '../LogoType'


const HomeLayout = ( {children} ) => {
	
	const animation = useSelector(store => store.anim)
    // console.log("animation.played in HOME PAGE: ", animation.played);


	return (
	<>
		<Helmet title="Home" />
		<Header />
		<ClientOnly>{!animation.played && <LogoTypeAnimation />}</ClientOnly>
		
		<main id="home" className="offset-y">
			<div className="anim-bak">
				<div className="bak-wrap container">
					<ContentHome />
				</div>
			</div>
		</main>
		<Footer />
	</>
)};

export default HomeLayout;

