import React from 'react'
import { Link, graphql, useStaticQuery} from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import './WorkUnitFeaturedStyles.scss'

const query = graphql`{
  allWorkunitJson(filter: {featured: {eq: true}}) {
    nodes {
      jsonId
      alt
      bulletPoints
      description
      featured
      image {
        childImageSharp {
          gatsbyImageData(
            width: 800
            quality: 95
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
        publicURL
      }
      role
      slug
      subTitle
      tags {
        name
        definition
        class
        definitionDescription
      }
      title
    }
    totalCount
  }
}
` 
function WorkUnitFeatured() {
    
    const results = useStaticQuery(query)
    const projects = results.allWorkunitJson.nodes
    // console.log(projects)
    // I'd like to use this refs array but we are not referencing the div we want to change, so not sure yet how to do this.
    // const refArray = useRef([])
    // refArray.current = projects.map((_, i ) => refArray.current[i] = createRef())
    // console.log("refArray: ", refArray);
    // used as ref down below { ref={refArray.current[index]} } 

    const tagRolloverHandler = (event) => {
      // console.log("rollover")
      event.preventDefault()
      event.stopPropagation()
        const defText = event.currentTarget.getAttribute("data-def")
        const defTargetId = event.currentTarget.getAttribute("aria-describedby")
        const defTarget = document.getElementById(defTargetId)
        defTarget.innerHTML = defText
    }

    const tagRollOutHandler = (event) => {
      // console.log("rollout")
      event.preventDefault()
      event.stopPropagation()
      const defTargetId = event.currentTarget.getAttribute("aria-describedby")
      const defTarget = document.getElementById(defTargetId)
      defTarget.innerHTML = ""
    }

    function makeHTML(htmlContent) { return {__html: htmlContent}; };
    
    return <>
    { projects.map((unit, index) => {
      
        const projectThumb = getImage(unit.image)
        // console.log("Project Thumb: ", projectThumb)
  
        const {tags} = unit;

        return (
          <div
            key={""+unit.jsonId}
            className={`featured-work-unit unit-${index + 1}`}
          >
            <div className="project-header">
              <h2 className="h2">
                <Link to={`/development/project/${unit.slug}`}>
                  {unit.title}
                </Link>
              </h2>
              <div className="work-tags" role="group" aria-label="Technology used in this project">
                <dfn id={`acc-${unit.jsonId}`} className="definition" aria-live="polite"></dfn> 
                <ul>
                  {tags.map((tag, index) => {
                    return (
                      <li key={""+index}>
                        <abbr 
                            data-def={tag.definition}
                            onMouseEnter={tagRolloverHandler}
                            onMouseOut={tagRollOutHandler}
                            onFocus={tagRolloverHandler}
                            aria-describedby={`acc-${unit.jsonId}`}
                            tabIndex="0"
                         >{tag.name}</abbr>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <div className="project-content">
              <div className="project-description">
                <h3 className="h3">{unit.role}</h3>
                <div dangerouslySetInnerHTML={makeHTML(unit.description)} />

                <ul>
                  {unit.bulletPoints.map((point, index) => {
                    return <li key={""+index}>{point}</li>
                  })}
                </ul>
                <Link
                  to={`/development/project/${unit.slug}`}
                  className="see-more"
                >
                  View project
                </Link>
              </div>
              <div className="project-thumb">
                <Link
                  to={`/development/project/${unit.slug}`}
                  title="View project"
                >
                  
                  <GatsbyImage image={projectThumb} alt={unit.alt} className="work-img" />
                   
                </Link>
              </div>
            </div>
          </div>
        )

    }) 
 }
 </>;
}



export default WorkUnitFeatured
